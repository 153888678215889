/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Heading, Flex, Link, Paragraph } from 'reakit'
import moment from 'moment'

import Layout from '../../components/layout'

import { Container, Sidebar, Body, Modal, Form, Footer } from '../../components/layouts'
import { ProjectBarChart, DynamicTable,  Loader} from '../../components/ui'

import theme from '../../theme/content'

import {
	getDonations,
	getProjects,
	changeSelectedProject,
	addProj,
	clearErrors,
	refreshToken
} from '../../factory'

import currencyFormat from '../../util/currencyFormat'

class Projects extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			errors: null,
			update: false
		}

		this.getDonations = this.getDonations.bind(this)
		this.getProjects = this.getProjects.bind(this)
		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.editNewProj = this.editNewProj.bind(this)
		this.createNewProj = this.createNewProj.bind(this)
		this.validateValues = this.validateValues.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then((res) => {
			this.getDonations(res)
		})

		this.getProjects()
		this.props.onClearErrors()
		this.getBodyWidth()

		window.addEventListener('resize', this.getBodyWidth.bind(this))
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.error && !this.state.errors){
			this.setErrors(nextProps.error)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 500)
		})
	}

	setErrors(errors){
		this.setState({errors})
	}

	getDonations(token) {
		this.props.onGetDonations(this.props.organization.id, token ? token : this.props.token)
	}

	getProjects() {
		this.props.onGetProjects(this.props.organization.id)
	}

	editNewProj(e){
		this.setState({[e.target.name]: e.target.value})
		if (this.state.errors){
			this.validateValues()
		}
	}

	createNewProj(e){
		const data = this.state

		data.organization_id = this.props.organization.id
		data.end_date = moment(data.end_date, 'MM-DD-YY').format('MM-DD-YY hh:mm:ss')

		this.props.onAddProject(data, this.props.token, () => {
			this.getProjects()
		})
	}

	validateValues() {

		const {end_date, donates_goal, name, description} = this.state
		const now = moment()
		if (!(end_date && donates_goal && name && description)){
			this.setState({errors: 'All fields are required.'})
			return false
		}
		const [month, day, year] = end_date.split('-')
		if (!(month.length == 2 && day.length == 2 && year.length == 2)){
			this.setState({errors: 'The date must be in the format MM-DD-YY'})
			return false
		}
		if (now.isAfter(moment(end_date, 'MM-DD-YYYY'))){
			this.setState({errors: 'The end date must be in the future.'})
			return false
		}

		this.setState({errors: null})
		return true
	}


	render() {
		const content = theme.pagesContent.project
		return(
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<Flex margin="1em 0em" column alignItems="center" justifyContent="space-around">
						{this.props.projects && this.props.projects.length > 0 &&
								<Card width={'100%'}>
									<Flex row justifyContent="space-between">
										<Flex row>
											<Heading as="h4" margin="auto 0" marginRight="1em">Your Projects</Heading>
											{this.props.pending && <Loader/>}
										</Flex>
										<Modal
											containterStyle={{minWidth: '70%'}}
											button={{text: 'New Project', width:'10em'}}
										>
											<Heading as="h4">New Project</Heading>
											<Form
												validate={this.validateValues}
												fields={content.projectForm.fields}
												setValues={this.editNewProj}
												submitForm={this.createNewProj}
												submitBtnText={content.projectForm.submitField.text}
												error={this.state.errors || this.props.error}
												logging={this.props.pending}
											/>
										</Modal>
									</Flex>
									<ProjectBarChart data={this.props.projects} width={this.state.bodySize} />
								</Card>
							}
						</Flex>
						<div style={{minHeight: 'calc(100vh - 408px)'}}>
						<Card marginBottom="2em" padding="1em" width="100%">
							{this.props.projects && <DynamicTable loading={false} data={this.props.projects} columns={[
								{
									Header: 'Name',
									accessor: 'name',
									width: 500,
									Cell: row => (<Link onClick={() => this.props.onSelectProject(row.original)} href={`/admin/project/?projectId=${row.original.id}`} color={theme.colors.crusta}>{row.original.name}</Link>)
								}, {
									Header: '# Donors',
									accessor: 'donor_qty',
									style: {textAlign: 'center'},
								}, {
									Header: '$ Raised',
									accessor: 'donates_current',
									style: {textAlign: 'right'},
									Cell: props => currencyFormat(props.value)
								}, {
									Header: 'Goal',
									accessor: 'donates_goal',
									style: {textAlign: 'right'},
									Cell: props => currencyFormat(props.value)
								},
								{
									Header: 'End Date',
									accessor: 'end_date',
									style: {textAlign: 'right'},
									Cell: props => props.value.split(' ')[0]
								}
							]}
							sortBy={[
								{
									id: "donates_current",
									desc: true
								}
							]} />}
						<Paragraph style={{fontSize: 14}}>Please note that pending donations are not included in totals. It may take up to 3 business days for a pending donation to fully process.</Paragraph>
						</Card>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

Projects.propTypes = {
	onGetDonations: PropTypes.func,
	onGetProjects: PropTypes.func,
	onGetStatistics: PropTypes.func,
	onSelectProject: PropTypes.func,
	onAddProject: PropTypes.func,
	donations: PropTypes.array,
	organization: PropTypes.object,
	token: PropTypes.string,
	error: PropTypes.any,
	location: PropTypes.object,
	stats: PropTypes.object,
	projects: PropTypes.array,
	pending: PropTypes.bool,
	onClearErrors: PropTypes.func,
	refreshToken: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		donations: state.adminState.donations,
		projects: state.donateNowState.projects,
		stats: state.statistics,
		token: state.authState.accessToken,
		error: state.adminState.error,
		pending: state.adminState.pending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetDonations: (orgId, token) => dispatch(getDonations(orgId, token)),
		onGetProjects: (orgId) => dispatch(getProjects(orgId)),
		onGetStatistics: (params, token) => dispatch(getStatistics(params, token)),
		onSelectProject: (project) => dispatch(changeSelectedProject(project)),
		onAddProject: (data, token, callback) => dispatch(addProj(data, token, callback)),
		onClearErrors: () => dispatch(clearErrors()),
		refreshToken: (token) => dispatch(refreshToken(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Projects)
